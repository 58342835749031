import "@hotwired/turbo-rails";

import { application } from "./controllers/application";

import FlowerController from "./controllers/flower_controller";
application.register("flower", FlowerController);

import InputLimitController from "./controllers/input_limit_controller";
application.register("input-limit", InputLimitController);

import InvoiceController from "./controllers/invoice_controller";
application.register("invoice", InvoiceController);

import MapController from "./controllers/map_controller";
application.register("map", MapController);

import NavigationController from "./controllers/navigation_controller";
application.register("navigation", NavigationController);

import FlashController from "./controllers/flash_controller.js";
application.register("flash", FlashController);
