import { Controller } from "@hotwired/stimulus";

function toggle(element) {
  if (element.style.display === "none") {
    element.style.display = "block";
  } else {
    element.style.display = "none";
  }
}

export default class extends Controller {
  static targets = ["detailToggle"];

  connect() {
    this.invoiceFields = this.element.querySelectorAll(".js-invoice-field");
    this.invoiceDetailFields = this.element.querySelectorAll(
      ".js-invoice-detail-field"
    );
  }

  toggle(event) {
    if (event.target.checked) {
      this.detailToggleTarget.parentNode.parentNode.style.display = "block";
      this.detailToggleTarget.style.display = "inline";
      this.detailToggleTarget.checked = true;
      this.detailToggleTarget.disabled = false;

      this.invoiceFields.forEach(toggle);
    } else {
      this.detailToggleTarget.parentNode.parentNode.style.display = "none";
      this.detailToggleTarget.style.display = "none";
      this.detailToggleTarget.checked = false;
      this.detailToggleTarget.disabled = true;

      this.invoiceFields.forEach(toggle);

      this.invoiceDetailFields.forEach((element) => {
        element.style.display = "none";
      });
    }
  }

  toggleDetails() {
    this.invoiceDetailFields.forEach(toggle);
  }
}
