/* global google, iconURL */

import { Controller } from "@hotwired/stimulus";

const styles = [
  {
    featureType: "administrative.locality",
    elementType: "all",
    stylers: [
      { hue: "#2c2e33" },
      { saturation: 7 },
      { lightness: 19 },
      { visibility: "on" },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      { hue: "#ffffff" },
      { saturation: -100 },
      { lightness: 100 },
      { visibility: "simplified" },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      { hue: "#ffffff" },
      { saturation: -100 },
      { lightness: 100 },
      { visibility: "off" },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      { hue: "#bbc0c4" },
      { saturation: -93 },
      { lightness: 31 },
      { visibility: "simplified" },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      { hue: "#bbc0c4" },
      { saturation: -93 },
      { lightness: 31 },
      { visibility: "on" },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels",
    stylers: [
      { hue: "#bbc0c4" },
      { saturation: -93 },
      { lightness: -2 },
      { visibility: "simplified" },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      { sue: "#e9ebed" },
      { saturation: -90 },
      { lightness: -8 },
      { visibility: "simplified" },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      { hue: "#e9ebed" },
      { saturation: 10 },
      { lightness: 69 },
      { visibility: "on" },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      { hue: "#e9ebed" },
      { saturation: -78 },
      { lightness: 67 },
      { visibility: "simplified" },
    ],
  },
];

export default class extends Controller {
  async connect() {
    if (typeof google != "undefined") {
      await this.initializeMap();
    }
  }

  async initializeMap() {
    const { Map, StyledMapType, InfoWindow } = await google.maps.importLibrary(
      "maps"
    );
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    const map = new Map(this.element, {
      mapId: "DEMO_MAP_ID",
      zoom: 10,
      center: { lat: 50.9066275, lng: 3.0187468 },
      disableDefaultUI: true,
      scrollwheel: false,
      draggable: false,
    });

    const styledMapType = new StyledMapType(styles, {
      name: "Vandecandelaere - Spruytte",
    });

    map.mapTypes.set("styled-map", styledMapType);
    map.setMapTypeId("styled-map");

    // Passendale

    const passendaleInfoWindow = new InfoWindow({
      content: `<div class="map-popup">
      <h5>Passendale</h5>
      <p>
        Westrozebekestraat 25A<br>
        8980 Passendale<br>
        Tel. +32 (0) 51 770 759<br>
        Fax. +32 (0) 51 780 615<br>
        <a href="mailto:info@vandecandelaere-spruytte.be" target="_blank" title="E-mail ons">info@vandecandelaere-spruytte.be</a>
      </p>
    </div>`,
    });

    const passendaleMarker = new AdvancedMarkerElement({
      map,
      position: { lat: 50.9066275, lng: 3.0187468 },
      title: "Passendale",
    });

    google.maps.event.addListener(passendaleMarker, "click", () => {
      passendaleInfoWindow.open(map, passendaleMarker);
    });

    // Roeselare

    const roeselareInfoWindow = new InfoWindow({
      content: `<div class="map-popup">
      <h5>Roeselare</h5>
      <p>
        Kleine Bruanestraat 19<br>
        8800 Roeselare<br>
        Tel. +32 (0) 51 240 103<br>
        <a href="mailto:info@vandecandelaere-spruytte.be" target="_blank" title="E-mail ons">info@vandecandelaere-spruytte.be</a>
      </p>
    </div>`,
    });

    const roeselareMarker = new AdvancedMarkerElement({
      map,
      position: { lat: 50.9419291, lng: 3.1438954 },
      title: "Roeselare",
    });

    google.maps.event.addListener(roeselareMarker, "click", () => {
      roeselareInfoWindow.open(map, roeselareMarker);
    });

    // Staden

    const stadenInfoWindow = new InfoWindow({
      content: `<div class="map-popup">
      <h5>Staden</h5>
      <p>
        Charles Ampestraat 2A<br>
        8840 Staden<br>
        Tel. +32 (0) 51 701 253<br>
        <a href="mailto:info@vandecandelaere-spruytte.be" target="_blank" title="E-mail ons">info@vandecandelaere-spruytte.be</a>
      </p>
    </div>`,
    });

    const stadenMarker = new AdvancedMarkerElement({
      map,
      position: { lat: 50.9788556, lng: 3.0080007 },
      title: "Staden",
    });

    google.maps.event.addListener(stadenMarker, "click", () => {
      stadenInfoWindow.open(map, stadenMarker);
    });
  }
}
