import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["output"];

  keyup(e) {
    const numberOfCharacters = e.target.value.length;
    const limit = e.target.maxLength;
    this.outputTarget.textContent = `${numberOfCharacters}/${limit}`;
  }
}
