import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["priceSelect"];

  change(event) {
    this.fetchPrices(event.target.value);
  }

  fetchPrices(kind) {
    const target = this.priceSelectTarget.id;

    fetch(`/flower_prices?kind=${kind}&target=${target}`, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": this.getCsrfToken(),
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }

  getCsrfToken() {
    return document.querySelector('meta[name="csrf-token"]').content;
  }
}
