import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["links", "trigger"];

  toggle() {
    this.linksTarget.classList.toggle("is-open");
    this.triggerTarget.classList.toggle("is-open");
  }
}
